<template>
    <Section class="footer-stick" :options="{container:false}">

        <div id="section-buy" class="heading-block text-center border-bottom-0 page-section">
            <h2>Contactez-nous</h2>
            <span>Besoin d'un renseignement? Une question?</span>
        </div>

        <div class="center contact-buttons" ref="button">
            <a href="javascript:void(0)" @click="onButtonClick(0)" data-animate="jello" class="button button-3d button-xlarge"><i class="icon-star3"></i>Contactez Louis Bensa</a>
            <a href="javascript:void(0)" @click="onButtonClick(1)" data-animate="jello" class="button button-3d button-xlarge"><i class="icon-star3"></i>Contactez Frédéric Devot</a>
        </div>

    </Section>
</template>

<style>
@media (max-width: 575.98px) {
    .contact-buttons a.button {
        display: block;
        padding:9px 16px;
    }
}

@media (max-width: 399.98px) {
    .contact-buttons a.button {
        font-size: 14px;
    }
}
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';
import { types as layoutTypes } from '@root/src/client/store/layout';
import { types as componentsTypes } from '@fwk-client/modules/cms/stores/components';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Contact extends Vue {

    description = [
        '<p>Contactez Louis Bensa (<a href="mailto:louis@bensadevot.fr">louis@bensadevot.fr</a>)</p>',
        '<p>Contactez Frédéric Devot (<a href="mailto:frederic@bensadevot.fr">frederic@bensadevot.fr</a>)</p>'
    ]

    mounted() {
        animateElements(this.$refs.button as HTMLElement)
    }

    beforeDestroy() {
        removeElementsAnimation(this.$refs.button as HTMLElement)
    }

    onButtonClick(contactIndex:number) {
        // We set the custom description

        // We update the store for component properties
        this.$store.commit('cms/components/' + componentsTypes.mutations.SET_COMPONENT_PROP, { 
            componentPath : "canvas/ContactModal",
            propName : 'contactIndex',
            propValue : contactIndex 
        })
        this.$store.commit('cms/components/' + componentsTypes.mutations.SET_COMPONENT_PROP, { 
            componentPath : "canvas/ContactModal",
            propName : 'description',
            propValue : this.description[contactIndex]
        })
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }
    
}
</script>